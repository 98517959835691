import IconButton from 'components/common/IconButton';
import { sleep } from 'helpers/utils';
import { trim } from 'lodash';
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

const ManualAddKeywordModal = ({
  selectedRowIds = {},
  setKeywords,
  keywords,
  maxSelection,
  setInitiallySelectedRowIds = arg => {},
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const handleClose = () => {
    setShow(false);
    setError('');
    reset();
  };

  const { handleSubmit, register, reset } = useForm();

  const onSubmit = formData => {
    const formKeywords = [
      ...new Set(
        formData.keywords
          ?.split('\n')
          .map(keyword => trim(keyword).toLowerCase())
          .filter(keyword => keyword != '')
      )
    ];

    let newTopKeywords = [];

    let newInitiallySelectedRowIds = { };

    formKeywords.forEach((keyword, i) => {
      // Check if the keyword already exists in the keywords array
      const existingKeywordIndex = keywords.findIndex(
        fetchedKeyword => fetchedKeyword[0]?.keyword === keyword
      );

      if (existingKeywordIndex !== -1) {
        // If the keyword exists as a competitor, insert the manually added keyword into the same array
        keywords[existingKeywordIndex].splice(0, 0, {
          keyword: keyword,
          common_keywords: '-',
          total_keywords: '-',
          relevance: '-',
          points: '-',
          manually_added: true
        });
      } else {
        // If the keyword does not exist, add it as a new entry
        newTopKeywords.push([
          {
            keyword: keyword,
            common_keywords: '-',
            total_keywords: '-',
            relevance: '-',
            points: '-',
            manually_added: true
          }
        ]);
      }
    });

    const newKeywords = [...newTopKeywords, ...keywords];

    // handling for new keywords from form field
    newKeywords.forEach((keyword, i) => {
      const foundExisting = formKeywords.includes(keyword[0].keyword);

      if (maxSelection) {
        const totalCheckedKeywords = Object.keys(
          newInitiallySelectedRowIds
        ).length;

        if (totalCheckedKeywords < maxSelection && foundExisting) {
          newInitiallySelectedRowIds[+i] = true;
        }
      } else {
        if (foundExisting) {
          newInitiallySelectedRowIds[+i] = true;
        }
      }
    });

    // handling for pre-selected rows
    Object.keys(selectedRowIds).forEach(id => {
      if (maxSelection) {
        const totalCheckedKeywords = Object.keys(
          newInitiallySelectedRowIds
        ).length;

        if (totalCheckedKeywords < maxSelection) {
          newInitiallySelectedRowIds[+id + +newTopKeywords.length] = true;
        }
      } else {
        newInitiallySelectedRowIds[+id + +newTopKeywords.length] = true;
      }
    });

    setInitiallySelectedRowIds(newInitiallySelectedRowIds);
    setKeywords(newKeywords);
    handleClose();
  };

  return (
    <>
      <IconButton
        icon={'plus'}
        className="rounded-pill"
        variant="outline-primary"
        size="sm"
        onClick={() => setShow(true)}
        {...rest}
      >
        Add Manually
      </IconButton>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add custom keyword</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-3">
          <Form noValidate id="user-form" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label>Keyword</Form.Label>
              <Form.Control
                as="textarea"
                rows={7}
                autoFocus
                {...register('keywords', {
                  required: true
                })}
                isInvalid={!!error}
              />
              <Form.Control.Feedback type="invalid">
                {error || ''}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <IconButton
            icon="times-circle"
            size="sm"
            onClick={handleClose}
            variant="falcon-light"
          >
            Cancel
          </IconButton>
          <IconButton
            variant="outline-primary"
            className="rounded-pill"
            form="user-form"
            type="submit"
            icon="save"
            size="sm"
          >
            Save
          </IconButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManualAddKeywordModal;
