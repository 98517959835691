import CallApi from 'components/common/custom-components/CallApi';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ReactSelect from 'react-select';

const useLanguageSelectBox = (identifier = 'id') => {
  const getLanguages = async () => {
    const languages = await CallApi.get('/language', false);
    // Sort alfabetically by language name
    languages.data.sort((a, b) => (a.name > b.name ? 1 : -1));
    return languages;
  };
  const { data, refetch } = useQuery('languages', getLanguages, {
    placeholderData: { data: [] }
  });

  const LanguageSelectBox = ({
    name = 'language_id',
    className = '',
    isClearable = false,
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    isSearchable = false,
    placeholder = 'Please select a language',
    onValueChange = null,
    onChange = language => {},
    value,
    size = 'md',
    ...rest
  }) => {
    const [language, setLanguage] = useState(null);

    useEffect(() => {
      if (data?.data?.length > 0) {
        const language = data.data.find(language => language[identifier] == value);
        setLanguage(language);
        if (language) {
            onChange(language);
        }
      }
    }, [value]);

    const smSizeStyles = {
      control: provided => ({
        ...provided,
        minHeight: 10
      }),
      dropdownIndicator: provided => ({ ...provided, padding: 1 }),
      clearIndicator: provided => ({ ...provided, padding: 2 }),
      input: provided => ({ ...provided, padding: 0, margin: 0 })
    };

    const getStyles = () => {
      if (size == 'sm') return smSizeStyles;
    };

    return (
      <ReactSelect
        styles={getStyles()}
        placeholder={placeholder}
        options={data?.data}
        name={name}
        onChange={language => {
            onChange(language);
            onValueChange?.(language);
        }}
        value={language}
        getOptionValue={language => language[identifier]}
        getOptionLabel={language => language?.name}
        className={className}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={isMulti}
        isSearchable={isSearchable}
        {...rest}
      />
    );
  };

  return { LanguageSelectBox, refetch };
};

export default useLanguageSelectBox;
